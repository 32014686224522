.mentions {
    min-height: 80vh;
    p {
        color: #64748b;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        margin-top: 0;
        text-align: justify;
    }
}

.todo {
    margin-top: 50px;
    h2 {
        color: #111827;
        font-family: Handlee;
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    &__option {
        display: flex;
        gap: 10px;
        align-items: center;

        margin-left: 10px;

        p {
            color: #374151 !important;
            font-family: Poppins !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            margin-top: 5px !important;
            margin-bottom: 5px !important;
        }
    }
}

@import "../abstract/responsive";

.business {
    &__card {
        width: 100%;
        border-radius: 15px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 25px;

        padding: 30px;
        box-sizing: border-box;

        h2 {
            font-family: Handlee;
            font-size: 30px;
            font-weight: 400;
            width: auto;
            line-height: 86px;
            margin: 0;
        }

        p {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
        }

        a {
            text-decoration: none;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            transition: 400ms;

            &:hover {
                margin-left: 8px;
            }
        }
    }

    &--naturopathie {
        border: 3px solid #efacff;
        background: rgba(245, 208, 254, 0.6);
        h2 {
            color: #a21caf;
        }
        p,
        a {
            color: #a21caf;
        }
    }

    &--massage {
        border: 3px solid #83e3c6;
        background: rgba(167, 243, 208, 0.3);
        h2 {
            color: #059669;
            line-height: 43px;
        }
        p,
        a {
            color: #059669;
        }
    }

    &--magnetisme {
        border: 3px solid #c4b5fd;
        background: rgba(221, 214, 254, 0.3);
        h2 {
            color: #7e22ce;
        }
        p,
        a {
            color: #7e22ce;
        }
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        margin-bottom: 30px;

        @include respond-to("desktop") {
            gap: 20px;
        }
        @include respond-to("tablet") {
            flex-direction: column;
            gap: 20px;
        }
        @include respond-to("mobile") {
            flex-direction: column;
            gap: 20px;
        }
    }
}

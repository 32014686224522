@import "../abstract/responsive";

.contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    min-height: 80vh;

    @include respond-to("tablet") {
        grid-template-columns: 1fr;
    }

    @include respond-to("mobile") {
        grid-template-columns: 1fr;
    }

    h1 {
        color: #111827;
        font-family: Handlee;
        font-size: 40px;
        font-weight: 400;
        margin-top: 0;
    }

    p {
        color: #374151;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
    }

    &__phone {
        margin-top: 40px;

        &-wrap {
            display: flex;
            gap: 10px;
            align-items: center;

            a {
                text-decoration: none;
                color: #374151;
                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }

    &__map {
        p {
            color: #374151;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
        }
        iframe {
            border-radius: 10px;
            border: 6px solid #ea8bfa;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.03);
            box-sizing: border-box;
        }
    }
}

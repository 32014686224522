@import "../abstract/responsive";

.service {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    min-height: 60vh;

    @include respond-to("tablet") {
        grid-template-columns: 1fr;
    }

    @include respond-to("mobile") {
        grid-template-columns: 1fr;
    }

    margin-bottom: 100px;

    h1 {
        color: #111827;
        font-family: Handlee;
        font-size: 40px;
        font-weight: 400;
    }
    p {
        color: #374151;
        text-align: justify;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
    }

    span {
        color: #374151;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
    }

    b {
        color: #ea6cff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        border: 6px solid #ea8bfa;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
    }
}

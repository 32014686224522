.footer {
    background: linear-gradient(90deg, #e879f9 63.02%, #f472b6 100%);
    padding: 20px 0;

    @include respond-to("tablet") {
        display: none;
    }

    @include respond-to("mobile") {
        display: none;
    }

    a {
        text-decoration: none;
        color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
    }

    &_items {
        display: flex;
        gap: 40px;
        align-items: center;
    }

    &_rdv {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    hr {
        border-color: rgba(255, 255, 255, 0.08);
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &_name {
        font-family: Poppins;
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }

    &_mentions {
        text-decoration: none;
        color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
    }
}

.messenger {
    margin-top: 40px;
    p {
        color: #374151;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
    }
    &__link {
        display: inline-block;
        text-decoration: none;
        transition: 200ms;

        &-wrap {
            display: flex;
            align-items: center;
            gap: 10px;

            span {
                color: #fff;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 600;
            }
        }

        &:hover {
            transform: translate(0, -2px);
        }
        padding: 13px 15px;

        border-radius: 10px;
        background: linear-gradient(90deg, #e879f9 63.02%, #f472b6 100%);
    }
}

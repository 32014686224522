.meet {
    display: flex;
    justify-content: center;
    width: 100%;

    margin-top: 80px;
    margin-bottom: 80px;

    transition: 200ms;

    &__button {
        display: flex;
        align-items: center;

        height: 55px;
        border-radius: 10px;

        background: linear-gradient(90deg, #e879f9 63.02%, #f472b6 100%);

        width: fit-content;
        padding-left: 20px;
        padding-right: 20px;

        cursor: pointer;

        span {
            display: block;
            color: #fff;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            user-select: none;
        }

        &:hover {
            transform: translate(0, -5px);
        }
    }

    a {
        text-decoration: none;
    }
}

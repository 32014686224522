$mobile: 576px;
$tablet: 768px;
$desktop: 992px;

@mixin respond-to($media) {
    @if $media == "mobile" {
        @media screen and (max-width: $mobile) {
            @content;
        }
    } @else if $media == "tablet" {
        @media screen and (min-width: $mobile) and (max-width: $tablet) {
            @content;
        }
    } @else if $media == "desktop" {
        @media screen and (min-width: $tablet) and (max-width: $desktop) {
            @content;
        }
    } @else if $media == "large-desktop" {
        @media screen and (min-width: $desktop) {
            @content;
        }
    }
}

@import "../abstract/responsive";

.feedback {
    h2 {
        color: #0f172a;
        font-family: Handlee;
        font-size: 40px;
        font-weight: 400;
    }

    &__wrap {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
        justify-content: center;

        @include respond-to("tablet") {
            grid-template-columns: 1fr;
        }
        @include respond-to("mobile") {
            grid-template-columns: 1fr;
        }
    }

    &__card {
        h3 {
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
        }

        &_text {
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            margin-top: 15px;
        }

        &_source {
            color: #475569;
            text-align: center;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
        }

        &_header {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }
}

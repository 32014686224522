@import "../abstract/responsive";

@keyframes opacityKF {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.header {
    background: #ffffff;
    margin-bottom: 60px;

    .container {
        position: relative;
    }

    &__menu {
        position: absolute;
        bottom: -30px;
        width: 100%;
        height: 60px;
        border-radius: 10px;
        background: linear-gradient(90deg, #e879f9 63.02%, #f472b6 100%);

        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        line-height: 60px;
    }

    &__informations {
        display: flex;
        align-items: center;
        gap: 20px;

        height: 107px;
        padding-bottom: 30px;

        @include respond-to("mobile") {
            justify-content: center;
        }

        img {
            height: 70px;
        }

        h1 {
            color: #111827;
            font-family: Poppins;
            font-size: 22px;
            font-weight: 600;
            margin: 0;
        }
        p {
            color: #4b5563;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            margin: 0;
        }
    }

    &__desktop {
        @include respond-to("mobile") {
            display: none;
        }

        &__items-left {
            display: flex;
            gap: 25px;
            padding-left: 40px;
        }

        &__items-right {
            display: flex;
            gap: 20px;
            justify-content: flex-end;
            padding-right: 40px;
        }
    }

    &__mobile {
        display: none;
        justify-content: center;
        align-items: center;
        gap: 20px;

        cursor: pointer;
        user-select: none;

        span {
            display: block;
            color: #fff;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            text-decoration: none;
        }

        svg {
            fill: #fff;
        }

        @include respond-to("mobile") {
            display: flex;
        }
    }

    &__full-width {
        display: grid;
        grid-template-rows: 50px auto;
        z-index: 1000;

        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;

        animation: 300ms opacityKF;

        background: linear-gradient(90deg, #e879f9 63.02%, #f472b6 100%);

        &__wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            height: 100%;
        }

        &_header {
            width: 90%;
            margin-left: 5%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            height: 50px;
            div {
                cursor: pointer;
            }
            svg {
                fill: #fff;
            }
        }

        a {
            display: block;
            color: #fff;
            font-family: Poppins;
            font-size: 25px;
            font-weight: 600;
            text-decoration: none;
        }
    }

    // COMPONENTS
    &__link {
        display: block;
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        position: relative;
        &::after {
            position: absolute;
            top: 70%;
            left: 0;
            content: "";
            width: 100%;
            height: 3px;
            background: rgba(255, 255, 255, 0.7);
            visibility: hidden;
        }
        &:hover::after {
            visibility: visible;
        }
    }

    &__select {
        position: relative;
        cursor: pointer;
        &:hover &_options {
            display: block;
        }
        &_title {
            display: flex;
            gap: 5px;
            align-items: center;
        }
        &_options {
            z-index: 100;
            position: absolute;
            display: none;
            margin-left: 50%;
            transform: translate(-50%, 0);
            width: auto;
            justify-content: flex-start;
            border-radius: 0 0 10px 10px;
            background: #fff;
            padding-top: 5px;
            a {
                text-decoration: none;
                display: block;
                color: #111827;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 600;
                padding: 10px 20px;
                line-height: 30px;
                &:hover {
                    color: #fff;
                    background: #e879fa;
                }
            }
        }
        span {
            display: block;
            color: #fff;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
        }
    }
}

@import "../abstract/responsive";

.presentation {
    min-height: 80vh;
    h1 {
        color: #111827;
        font-family: Handlee;
        font-size: 40px;
        font-weight: 400;
    }
    &__wrap {
        display: grid;
        grid-template-columns: 200px auto;
        align-items: center;
        gap: 20px;

        @include respond-to("tablet") {
            text-align: center;
            grid-template-columns: 1fr;
        }

        @include respond-to("mobile") {
            grid-template-columns: 1fr;
        }

        img {
            width: 100%;
            border-radius: 20px;

            @include respond-to("tablet") {
                width: 50%;
            }
        }

        p {
            color: #374151;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            text-align: justify;
            white-space-collapse: preserve;
        }
    }
}

@import "../abstract/responsive";

.office {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    margin-bottom: 30px;

    @include respond-to("desktop") {
        flex-direction: column;
    }
    @include respond-to("tablet") {
        flex-direction: column;
    }
    @include respond-to("mobile") {
        flex-direction: column;
    }

    &__informations {
        h2 {
            color: #0f172a;
            font-family: Handlee;
            font-size: 40px;
            font-weight: 400;
        }

        p {
            color: #0f172a;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
        }
    }

    &__wrap-img {
        display: grid;
        grid-template-rows: 250px 250px;
        align-content: space-between;
        gap: 20px;

        @include respond-to("desktop") {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: none;
        }
        @include respond-to("tablet") {
            grid-template-rows: 1fr;
        }
        @include respond-to("mobile") {
            grid-template-rows: 1fr;
        }
    }

    &__img2 {
        border-radius: 10px;
        height: 100%;

        @include respond-to("desktop") {
            height: auto;
            width: 100%;
        }
        @include respond-to("tablet") {
            height: auto;
            width: 100%;
        }

        @include respond-to("mobile") {
            height: auto;
            width: 100%;
        }
    }

    &__img3 {
        border-radius: 10px;
        height: 100%;

        @include respond-to("desktop") {
            height: auto;
            width: 100%;
        }
        @include respond-to("tablet") {
            height: auto;
            width: 100%;
        }
        @include respond-to("mobile") {
            height: auto;
            width: 100%;
        }
    }
}

@import "../abstract/responsive";

.carousel {
    height: 400px;
    width: 100%;

    // background-image: url("https://i.imgur.com/1LRYrV1.png");
    background-size: cover;
    background-position: center;
    border-radius: 10px;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 30px;

    &_opacity {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: rgba(0, 11, 6, 0.4);
    }

    h3 {
        z-index: 10;
        font-family: "Handlee", cursive;
        color: #fff;
        font-size: 40px;
        font-weight: 400;
        user-select: none;
        text-align: center;

        @include respond-to("tablet") {
            font-size: 30px;
        }
        @include respond-to("mobile") {
            font-size: 25px;
        }
    }
}

@import "./_responsive";

.container {
    margin: auto;
    transition: width 300ms;

    @include respond-to("large-desktop") {
        max-width: 1200px;
        width: 85%;
    }

    @include respond-to("desktop") {
        width: 90%;
    }

    @include respond-to("tablet") {
        width: 95%;
    }

    @include respond-to("mobile") {
        width: 95%;
    }
}
